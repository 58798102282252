import React from 'react';
import PropTypes from 'prop-types';
import Styles from './GeneratedScheduleBanner.module.css';
import { DatePicker } from 'op2mise-react-widgets';
import { addDaysToDate, subtractDaysToDate } from 'utils';
import Select from 'shared/components/select/Select';
import moment from 'moment';
import { formatDateWithMoment } from 'utils';

function GeneratedScheduleBannerComponent({
  componentName,
  scheduleDetails,
  dateRange,
  callbackChangeDateRange,
  scheduleID,
}) {
  const getDate = (date) => {
    return (
      date && new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    );
  };

  const errorStyle = {
    color: 'var(--op2mise-color-red)',
    fontSize: '12px',
  };
  const today = new Date();

  return (
    <div className={`${Styles.header} position-relative`}>
      <b className={Styles.headerText}>{componentName}</b>
      <div className="d-flex position-absolute end-0">
        <div className={`row ${Styles.marginBottom}`}>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Channel:</div>
          </div>
          <div
            className={Styles.divHeaderPadding}
            style={{ paddingTop: '0', marginTop: '-3px' }}
          >
            <Select
              text={scheduleDetails.channelName}
              list={[]}
              onSelect={() => {}}
              width="200px"
              disabled={true}
            />
          </div>
          <>
            <div
              className={`
              ${Styles.divHeaderPadding} 
              ${Styles.paddingTopNone} 
              ${Styles.customDateInput}`}
              style={{
                // marginTop: '-6px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <div
                style={{
                  marginTop: '-6px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <DatePicker
                  allowEdit={false}
                  cssClass="view-schedules-date-picker"
                  format={'dd/MM/yyyy'}
                  label={
                    scheduleDetails.playlistDate ? 'Period: ' : 'Period from:'
                  }
                  date={scheduleDetails.playlistDate ?? dateRange.start}
                  min={
                    scheduleID === '' || scheduleID === 0
                      ? today
                      : dateRange.start
                  }
                  onChange={(e) => {
                    if (moment(e.target.value).isAfter(moment(dateRange.end))) {
                      callbackChangeDateRange({
                        start: e.target.value,
                        end: e.target.value,
                      });
                    } else {
                      callbackChangeDateRange({
                        start: e.target.value,
                        end: dateRange.end,
                      });
                    }
                  }}
                  showClearButton={false}
                  placeholder="dd/MM/yyyy"
                  value={scheduleDetails.playlistDate ?? dateRange.start}
                  enabled={
                    scheduleID === '' ||
                    (scheduleID === 0 && !scheduleDetails.playlistDate)
                  }
                />
                {!scheduleDetails.playlistDate && (
                  <DatePicker
                    allowEdit={false}
                    cssClass="view-schedules-date-picker"
                    format={'dd/MM/yyyy'}
                    label="To:"
                    date={dateRange.end}
                    min={
                      scheduleID === '' || scheduleID === 0
                        ? today
                        : dateRange.start
                    }
                    onChange={(e) => {
                      if (
                        moment(e.target.value).isBefore(moment(dateRange.start))
                      ) {
                        callbackChangeDateRange({
                          start: e.target.value,
                          end: e.target.value,
                        });
                      } else {
                        callbackChangeDateRange({
                          start: dateRange.start,
                          end: e.target.value,
                        });
                      }
                    }}
                    showClearButton={false}
                    placeholder="dd/MM/yyyy"
                    value={dateRange.end}
                    enabled={
                      scheduleID === '' ||
                      (scheduleID === 0 && !scheduleDetails.playlistDate)
                    }
                  />
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
GeneratedScheduleBannerComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  scheduleDetails: PropTypes.shape(),
};
export default GeneratedScheduleBannerComponent;
