export const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const APIContexts = {
  CHANNEL: 'Channel',
  INTERSTITIAL: 'Interstitial',
  PLAYLIST: 'Playlist',
  SCHEDULE: 'Schedule',
  SEASON: 'Season',
  TITLE: 'Title',
  TITLE_EXCLUSION: 'TitleExclusion',
  TITLE_GROUP: 'TitleGroup',
  USER: 'User',
};
