export const onSelectionChangedEvent = ({
  gridRef,
  onSelectionChanged,
  params,
  setSelectedRows,
}) => {

  const rows = gridRef.current.api.getSelectedNodes();
  // Need to include params in the argument
  // NOTE: onSelectionChanged(params)
  if (onSelectionChanged) {
    onSelectionChanged(rows.filter(data => data.selected));
  };
  
  if (setSelectedRows) {
    const selectedRows = params.api.rowModel.rowsToDisplay.filter(data => data.selected).length;
    setSelectedRows(selectedRows);
  };

  gridRef.current.api.refreshCells({ force: true }); // this line allows the cell color to go back to it's intial color setting after being deselected
}