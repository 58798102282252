import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { registerLicense } from '@syncfusion/ej2-base';
import App from './App';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    environment: `${process.env.REACT_APP_SENTRY_ENV}`,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          `${process.env.REACT_APP_HOST_URL}`,
          `${process.env.REACT_APP_BASE_HREF}`,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// Registering Syncfusion license key
registerLicense(
  'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9Sd0BjUHtedHdXQGRZ'
);

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#008662',
    },
    secondary: {
      main: '#0c212b',
    },
    text: {
      primary: '#0c212b',
      secondary: 'rgba(12,33,43,0.6)',
      disabled: 'rgba(12,33,43,0.38)',
    },
    error: {
      main: '#f35d5d',
    },
    warning: {
      main: '#f5b041',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    button: {
      fontSize: 12,
      fontWeight: 100,
      letterSpacing: 'normal',
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0px 24px',
          height: 36,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          height: 42,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          height: 21,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          height: 42,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          height: 42,
        },
      },
    },
  },
});

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);
