/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  applyCapitalization,
  applyShadesOnBookedColumn,
  applySharedColumnProperties,
  applyStyleOnChildHeaderCell,
  gridStateHelper,
  gridStyleHelper,
  onColumnVisibleEvent,
  onFilterChangedEvent,
  onGridPreDestroyedEvent,
  onGridReadyEvent,
  onSelectionChangedEvent,
  onSortChangedEvent,
  onStateUpdatedEvent,
} from '../../utils';
import { GetGridStateAPI } from 'api';
import config from '../../ag-config.properties.json';
import { FooterTemplate, LoaderTemplate, LoaderTemplateForRow } from '../../Templates';
import { Themes } from '../../';
import '../../stylesheets/ag-theme-op2mise-2.css';
import '../../stylesheets/ag-theme-op2mise.css';
import '../../stylesheets/ag-theme-op2mise-simple.css';

const { Constants } = config;

export function BaseGrid(props) {
  const {
    columns,
    columnDefinition,
    customFooterElement,
    defaultColumnProperties,
    detailCellRenderer,
    detailCellRendererParams,
    detailRowHeight,
    disableHighlightOnBookedColumn,
    enableMultipleColumnSorting,
    enableMultipleRowSelection,
    gridHeightBuffer,
    gridLabel,
    gridName,
    groupDefaultExpanded,
    loading,
    baseGridRef,
    masterDetail,
    noRowsOverlayComponent,
    onColumnVisible,
    onFilterChanged,
    onGridPreDestroyed,
    onGridReady,
    onSelectionChanged,
    onSortChanged,
    onStateUpdated,
    overviewGridRef,
    pagination,
    paginationPageSize,
    rowClassRules,
    rows,
    setColumnDefinition,
    setIsVerticalScroll,
    sharedColumnProperties,
    showAlternativeRowHighlight,
    showRowSelectionCount,
    suppressMovableColumns,
    theme,
    useCheckboxForRowSelection,
  } = props;

  const [columnIsWithChildren, setColumnIsWithChildren] = React.useState(false);
  const [gridState, setGridState] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(paginationPageSize);
  const [rowData, setRowData] = React.useState(rows);
  const [rowsToDisplay, setRowsToDisplay] = React.useState(rows.length);
  const [selectedRows, setSelectedRows] = React.useState(0);
  const [isFirstDataRendered, setIsFirstDataRendered] = React.useState(false);
  const [isGridReady, setIsGridReady] = React.useState(false);
  const [isGridRowEmptyAfterFilter, setIsGridRowEmptyAfterFilter] = React.useState(false);
  const [isGridRowStillProcessing, setIsGridRowStillProcessing] = React.useState(false);
  const [isGridStillProcessing, setIsGridStillProcessing] = React.useState(true);

  const gridWrapperStyle = React.useCallback((buffer) => gridStyleHelper({ gridHeightBuffer: buffer }), []);
  const eventHandlingProps = React.useMemo(() => {
    return {
      columnDefinition,
      gridName,
      gridRef: baseGridRef,
      gridState,
      onColumnVisible,
      onFilterChanged,
      onGridPreDestroyed,
      onGridReady,
      onSelectionChanged,
      onSortChanged,
      onStateUpdated,
      setSelectedRows,
      setRowsToDisplay,
    }
  }, [
    columnDefinition,
    gridName,
    gridState,
    onColumnVisible,
    onFilterChanged,
    onGridPreDestroyed,
    onGridReady,
    onSelectionChanged,
    onSortChanged,
    onStateUpdated,
  ]);

  // Auto run for capitalizing header name and header tooltip texts
  const handleCapitalization = React.useCallback((columnInstance) =>
    applyCapitalization({ columnInstance })
    , []);

  // Auto run for setting background color (light/dark colors) on columns that contains `book` in the `field` column attribute
  const handleBookedColumn = React.useCallback((columnInstance) =>
    applyShadesOnBookedColumn({ columnInstance, disableHighlightOnBookedColumn, setColumnIsWithChildren })
    , [disableHighlightOnBookedColumn]);

  // Auto run for setting background color (pine-gree color) on CHILD header cells
  const handleChildHeaderCell = React.useCallback((columnInstance) =>
    applyStyleOnChildHeaderCell({ columnInstance })
    , []);

  // Auto run for memoized callback to apply shared column properties accross multiple columns.
  const handleSharedColumnProperties = React.useCallback((columnInstance) =>
    applySharedColumnProperties({ columnInstance, sharedColumnProperties })
    , [sharedColumnProperties]);

  const executeAllAutoRuns = React.useCallback(() => {
    // Executes all auto-run functions
    const columnInstance = columnDefinition;
    const autoRuns = [
      handleBookedColumn,
      handleCapitalization,
      handleChildHeaderCell,
      handleSharedColumnProperties,
    ];
    const refactoredColumns = autoRuns.reduce((column, func) => func(column), columnInstance);
    setColumnDefinition(refactoredColumns);
  }, [
    columnDefinition,
    handleBookedColumn,
    handleCapitalization,
    handleChildHeaderCell,
    handleSharedColumnProperties,
  ]);

  // Re-hydrate rowdata when filtering causes the grid to be empty.
  // Render a row loader before and after grid becomes empty.
  const rehydrateGridRows = React.useCallback((params) => {
    if (isGridRowEmptyAfterFilter) {
      setIsGridRowEmptyAfterFilter(false);
      setIsGridRowStillProcessing(true);
      setTimeout(() => setIsGridRowStillProcessing(false), 300);
    }

    if (!params.api.rowModel.rowsToDisplay.length) {
      setIsGridRowEmptyAfterFilter(true);
      setIsGridRowStillProcessing(true);
      setTimeout(() => setIsGridRowStillProcessing(false), 300);
    }
  }, [isGridRowEmptyAfterFilter]);

  // Event handle for: onFilterChanged event
  const handleOnFilterChanged = React.useCallback((params) => {
    rehydrateGridRows(params);
    onFilterChangedEvent({ params, ...eventHandlingProps });
  }, [isGridRowEmptyAfterFilter, eventHandlingProps, eventHandlingProps]);

  // Event handler for: onSelectionChanged event
  const handleOnSelectionChanged = React.useCallback((params) => onSelectionChangedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // Event handler for: onGridReady event
  const handleOnGridReady = React.useCallback((params) => {
    onGridReadyEvent({ params, ...eventHandlingProps});
    setIsGridReady(true);
  }, [eventHandlingProps]);

  // Event handler for: onSortChanged event
  const handleOnSortChanged = React.useCallback((params) => onSortChangedEvent({ params, ...eventHandlingProps}), [eventHandlingProps]);

  // Event handler for: onStateUpdated event
  const handleOnStateUpdated = React.useCallback((params) => onStateUpdatedEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  // Event handler for: onGridPreDestroyed event
  const handleOnGridPreDestroyed = React.useCallback((params) => onGridPreDestroyedEvent({ params, ...eventHandlingProps}), [eventHandlingProps]);

  // Event handler for: onColumnVisible event
  const handleOnColumnvisible = React.useCallback((params) => onColumnVisibleEvent({ params, ...eventHandlingProps }), [eventHandlingProps]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for invoking all auto-run functions
     * Auto-run functions are customized features.
     * See: /utils/autoRuns
     */

    executeAllAutoRuns();
  }, []);

  React.useEffect(() => {
    /*
     * Side-effect for fetching Grid State
     * Pre-configures state before loading the grid into the Client
     */

    if (gridName) {
      GetGridStateAPI({
        queryParams: { gridName },
        onSuccess: (res) => {
          const state = res.state ? JSON.parse(res.state) : {};
          if (res.state) gridStateHelper(state, baseGridRef);
          setGridState(state);
        },
      });
    }
  }, []);

  React.useEffect(() => {
    baseGridRef.current?.api?.setGridOption('columnDefs', columnDefinition);
  }, [columns]);

  React.useEffect(() => {
    /*
     * Base Grid Loader
     * Side-effect responsible for Loader component
     * Decides whether to use Grid level or Row level loaders
     */

    let timerForGrid;
    let timerForRow;
    const isGridEstablished = isFirstDataRendered;
    const isGridLoadingExternally = loading;
    const isGridLoadingInternally = !isGridReady;
    const isGridStateFetched = gridState;
    const shouldGridLoadWhenLoadingExternally = isGridLoadingExternally && !isGridEstablished;

    if (isGridLoadingExternally && !shouldGridLoadWhenLoadingExternally) {
      setIsGridRowStillProcessing(true);
    } else {
      timerForRow = setTimeout(() => setIsGridRowStillProcessing(false), 300);
    }

    if (!shouldGridLoadWhenLoadingExternally && !isGridLoadingInternally && isGridStateFetched) {
      timerForGrid = setTimeout(() => setIsGridStillProcessing(false), 900);
    } else {
      setIsGridStillProcessing(true);
    }

    return () => {
      clearTimeout(timerForGrid);
      clearTimeout(timerForRow);
    };
  }, [
    isFirstDataRendered,
    isGridReady,
    gridState,
    loading,
  ]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for hiding the default overlay message for empty rows
     * Prevents overlapping with BootstrapSpinner
     */

    if (loading || !isGridReady) {
      if (baseGridRef.current?.api) baseGridRef.current?.api.hideOverlay();
    }
  }, [loading, isGridReady]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for setting up row-data for the Grid
     */

    setRowData(rows);
    const rowLength = rows.length;
    if (rowLength > 200) {
      // Dynamically set a new page size if number of rows start to exceed 200
      const newPageSize = (Math.ceil(rowLength / 100) * 100);
      setPageSize(newPageSize);
    };
  }, [rows]);

  return (
    <React.Fragment>
      <span style={{ color: '#6d6d73', fontSize: '14px', fontWeight: 400, marginBottom: '5px' }}>{gridLabel}</span>
      <div className={theme === Themes.PRIMARY ? columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise' : 'ag-theme-op2mise-simple'} style={gridWrapperStyle(gridHeightBuffer)}>
        <LoaderTemplate {...{
          isLoading: isGridStillProcessing,
          height: gridWrapperStyle(gridHeightBuffer).height,
        }} />
        <LoaderTemplateForRow {...{
          isLoading: isGridRowStillProcessing,
          height: gridWrapperStyle(gridHeightBuffer + 51).height,
        }} />
        <AgGridReact
          alignedGrids={[overviewGridRef]}
          columnDefs={columnDefinition}
          defaultColDef={defaultColumnProperties}
          detailCellRenderer={detailCellRenderer}
          detailCellRendererParams={detailCellRendererParams}
          detailRowHeight={detailRowHeight}
          groupDefaultExpanded={groupDefaultExpanded}
          masterDetail={masterDetail}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onColumnVisible={handleOnColumnvisible}
          onFilterChanged={handleOnFilterChanged}
          onFirstDataRendered={(params) => setIsFirstDataRendered(true)}
          onGridReady={handleOnGridReady}
          onGridPreDestroyed={handleOnGridPreDestroyed}
          onRowGroupOpened={(params) => {
            const isExpanded = params.api.rowModel.rowsToDisplay.find((obj) => obj.expanded);
            if (isExpanded) {
              setIsVerticalScroll(true);
            } else {
              setIsVerticalScroll(false);
            }
          }}
          onSelectionChanged={handleOnSelectionChanged}
          onSortChanged={handleOnSortChanged}
          onStateUpdated={handleOnStateUpdated}
          pagination={pagination}
          paginationPageSize={pageSize}
          ref={baseGridRef}
          rowData={rowData}
          rowClassRules={{
            ...rowClassRules,
            ...(showAlternativeRowHighlight && {
              'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
            }),
          }}
          rowSelection={enableMultipleRowSelection ? 'multiple' : 'single'}
          rowMultiSelectWithClick={enableMultipleRowSelection}
          suppressMovableColumns={suppressMovableColumns}
          suppressRowClickSelection={useCheckboxForRowSelection}
          {...enableMultipleColumnSorting && { multiSortKey: 'ctrl' }}
          {...Constants.GridOptions}
        />
      </div>
      <FooterTemplate {...{
        loading: isGridStillProcessing,
        pagination,
        rowsToDisplay,
        customFooterElement,
        baseGridRef,
      }} />
      {showRowSelectionCount && (
        <p style={{ marginTop: '15px', color: '#6d6d73', fontSize: '12px', fontWeight: '400', letterSpacing: 0, lineHeight: '18px' }}>
          {selectedRows} rows selected
        </p>
      )}
    </React.Fragment>
  );
}
