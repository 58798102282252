import { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles.module.css';

const CustomTimeInput = forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      validate,
      onValidate,
      errorMessage,
      clearValues,
      min,
      max,
      defaultValue,
      customWrapper,
      channelForms,
      exceedMaxHour,
      maxHour,
    },
    ref
  ) => {
    const hourInputRef = useRef();
    const minuteInputRef = useRef();
    const [isChanged, setIsChanged] = useState(false);
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [changeTimes, setChangeTimes] = useState(0);
    const [isInvalid, setIsInvalid] = useState(false);
    const shouldValidate = typeof onValidate === 'function' && validate;

    // Restricting characters that is not convertable to number
    const getNumbersOnly = (string) => {
      const stringArray = string.split('');
      const result = stringArray.filter(
        (char) => !isNaN(char) && !isNaN(parseFloat(char))
      );
      return result.join('');
    };

    // Change cursor
    const focusToHour = () => {
      setTimeout(() => {
        hourInputRef.current?.focus();
        hourInputRef.current?.select();
      }, 1);
    };

    const focusToMinute = () => {
      setTimeout(() => {
        minuteInputRef.current?.focus();
        minuteInputRef.current?.select();
      }, 1);
    };

    const handleOnFocus = (e) => e.target.select();

    const handleOnClick = (e) => e.stopPropagation();

    const handleOnBlur = () => setChangeTimes(0);

    // Checking and setting values
    const isTwoDigit = (value) => value.length === 3;

    const pad = (value) => {
      if (value.length === 1) return `0${value}`;
      else if (value[0] === '0') return value.substring(1, 3);
      else return value;
    };

    const isValueValid = (value, inputName) => {
      const number = parseInt(value);
      if (inputName === 'hour') return number <= maxHour;
      else {
        if (maxHour >= 24 && hour === maxHour) return number === 0;
        else return number <= 59;
      }
    };

    const handleOnChange = (e) => {
      const name = e.target.name;
      let value = getNumbersOnly(e.target.value);
      value = pad(value);
      const isFirstDigitZero = value[0] === '0';
      const isNotValid = value && !isValueValid(value, name);

      if (
        name === 'hour' &&
        (changeTimes === 1 || value.length === 3) &&
        (value.length === 2 || isNotValid)
      )
        focusToMinute();
      if (!isFirstDigitZero && (isTwoDigit(value) || isNotValid)) return false;

      if (name === 'hour') {
        setHour(value);
        if (!hour && !minute) setMinute('00');
      } else if (!isNotValid) setMinute(value);
      setChangeTimes(changeTimes + 1);
      setIsChanged(true);
    };

    const constructTime = (value) => {
      const date = new Date(value);
      const dateHour = date.getHours();
      const dateMinute = date.getMinutes();
      const dateHourValue = pad(dateHour.toString());
      const dateMinuteValue = pad(dateMinute.toString());

      if (maxHour > 23) {
        const dHour =
          dateMinute === 0 && dateHour === 0 && !hour
            ? 24
            : hour >= 24
            ? hour
            : dateHourValue;
        setHour(dHour);
      } else {
        setHour(dateHourValue);
      }
      setMinute(dateMinuteValue);
    };

    // Keyboard arrow navigations
    const onKeyDownHour = (e) => {
      if (e.key === 'ArrowRight') focusToMinute();
      if (e.key === 'ArrowUp') {
        let incremented = parseInt(hour) + 1;
        if (incremented <= 23) setHour(pad(incremented.toString()));
      }
      if (e.key === 'ArrowDown') {
        let decremented = parseInt(hour) - 1;
        if (decremented >= 0) setHour(pad(decremented.toString()));
      }
      if (e.key === 'Backspace') {
        setHour('');
        setChangeTimes(0);
        setIsChanged(true);
      }
    };

    const onKeyDownMinute = (e) => {
      if (e.key === 'ArrowLeft') focusToHour();
      if (e.key === 'ArrowUp') {
        let incremented = parseInt(minute) + 1;
        if (incremented <= 59) setMinute(pad(incremented.toString()));
      }
      if (e.key === 'ArrowDown') {
        let decremented = parseInt(minute) - 1;
        if (decremented >= 0) setMinute(pad(decremented.toString()));
      }
      if (e.key === 'Backspace') {
        setMinute('');
        setIsChanged(true);
      }
    };

    const checkIfOutsideOfMinMax = (time) => {
      var isMaxTime = false;
      if (max) {
        let dateSplit = max.toLocaleString().split(' ');
        isMaxTime = dateSplit[2] == 'AM' && dateSplit[1] == '12:00:00';
      }
      return (
        (min ? time < min : false) || (max ? time > max && !isMaxTime : false)
      );
    };

    useEffect(() => {
      if (defaultValue) {
        const isValidDate = defaultValue && moment(defaultValue).isValid();
        if (isValidDate) constructTime(defaultValue);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (channelForms) {
        if ((value && !isChanged) || !hour || !minute) {
          const isValidDate = value && moment(value).isValid();
          if (isValidDate) constructTime(value);
        } else if (!value) {
          setHour('');
          setMinute('');
        }
      } else {
        if ((value && !isChanged) || !hour || !minute) {
          const isValidDate = value && moment(value).isValid();
          if (isValidDate) constructTime(value);
        }
      }
    }, [value]);

    useEffect(() => {
      if (isChanged) {
        if (hour && minute) {
          const time = new Date(
            0,
            0,
            0,
            parseInt(hour),
            parseInt(minute),
            0,
            0
          );
          if (typeof onChange === 'function') onChange(time);
          const isOutSideOfMinMax = checkIfOutsideOfMinMax(time);
          if (typeof onValidate === 'function') onValidate(isOutSideOfMinMax);
        } else {
          if (typeof onChange === 'function') onChange(null);
          setIsInvalid(!hour || !minute);
          if (typeof onValidate === 'function') onValidate(!hour || !minute);
        }
      } else {
        if (typeof onValidate === 'function') onValidate(false);
      }
    }, [hour, minute]);

    useEffect(() => {
      if (clearValues) {
        setHour('');
        setMinute('');
      }
    }, [clearValues]);

    return (
      <div className={styles.timeInput}>
        <div
          ref={ref}
          className={`
                    ${styles.timeInputWrapper}
                    ${shouldValidate && !disabled && styles.invalidInput}
                    ${disabled && styles.disabledInput}
                    ${customWrapper && customWrapper}
                `}
          onClick={focusToHour}
        >
          <input
            ref={hourInputRef}
            className={styles.hourInput}
            style={{ marginLeft: hour && '-5px' }}
            value={hour}
            name="hour"
            type="text"
            placeholder="HH"
            autoComplete="off"
            onChange={handleOnChange}
            onKeyDown={onKeyDownHour}
            onFocus={handleOnFocus}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
            disabled={disabled}
          />
          <span className={styles.inputDivider}>:</span>
          <input
            ref={minuteInputRef}
            className={styles.minuteInput}
            value={minute}
            name="minute"
            type="text"
            placeholder="MM"
            autoComplete="off"
            onChange={handleOnChange}
            onKeyDown={onKeyDownMinute}
            onFocus={handleOnFocus}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
            disabled={disabled}
          />
        </div>
        {shouldValidate && isInvalid && !disabled && (
          <span className={styles.errorMessage}>{errorMessage}</span>
        )}
      </div>
    );
  }
);
CustomTimeInput.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  validate: PropTypes.bool,
  onValidate: PropTypes.func,
  errorMessage: PropTypes.string,
  clearValues: PropTypes.bool,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  defaultValue: PropTypes.instanceOf(Date),
  customWrapper: PropTypes.shape(),
  channelForms: PropTypes.bool,
  exceedMaxHour: PropTypes.bool,
  maxHour: PropTypes.number,
};

CustomTimeInput.defaultProps = {
  maxHour: 23,
};
export default CustomTimeInput;
