export const applyCapitalization = ({ columnInstance }) => {
  // Iterate through each column in the columnInstance array
  return columnInstance.map(column => {
    // Determine the display name for the column header.
    // Use `headerName` if it exists, otherwise use the `field` attribute, converting it to uppercase.
    const displayName = column.headerName ? column.headerName.toUpperCase() : column.field.toUpperCase();

    // If the column has child columns, process each child column
    if (column.children && column.children.length) {
      column.children = column.children.map(child => {
        // Determine the display name for the child column header.
        // Use `headerName` if it exists, otherwise use the `field` attribute, converting it to uppercase.
        const childDisplayName = child.headerName ? child.headerName.toUpperCase() : child.field.toUpperCase();
        // Set the `headerName` for the child column to the capitalized display name
        child['headerName'] = childDisplayName;

        // If `headerTooltip` is not defined, set it to the capitalized display name
        if (!child.hasOwnProperty('headerTooltip')) {
          child['headerTooltip'] = childDisplayName;
        } else {
          // Otherwise, capitalize the existing `headerTooltip` value
          child['headerTooltip'] = child.headerTooltip.toUpperCase();
        }

        // If `tooltipValueGetter` is not defined, provide a default function that returns the cell value
        if (!child.hasOwnProperty('tooltipValueGetter')) {
          child['tooltipValueGetter'] = (params) => params.value;
        }

        return child;
      });
    }

    // Set the `headerName` for the column to the capitalized display name
    column['headerName'] = displayName;

    // If `headerTooltip` is not defined, set it to the capitalized display name
    if (!column.hasOwnProperty('headerTooltip')) {
      column['headerTooltip'] = displayName;
    } else {
      // Otherwise, capitalize the existing `headerTooltip` value
      column['headerTooltip'] = column.headerTooltip.toUpperCase();
    }

    // If `tooltipValueGetter` is not defined, provide a default function that returns the cell value
    if (!column.hasOwnProperty('tooltipValueGetter')) {
      column['tooltipValueGetter'] = (params) => params.value;
    }

    return column;
  });
}