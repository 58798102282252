
import { HttpMethods, APIContexts } from 'enums';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.TITLE}/`;

export const GetPrioritisationListAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetPrioritisationList';
  const endpoint = `${apiHost}${apiContext}${api}?channelId=${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetProgrammeTitlesListAPI = ({
  queryParams,
  requestBody,
  onSuccess,
  ...rest
}) => {
  const api = 'GetProgrammeTitlesList';
  const endpoint = `${apiHost}${apiContext}${api}?Page=1&Size=10000&channelId=${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    payload: requestBody,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};