import { HttpMethods, APIContexts } from 'enums';
import axios from 'axios';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.TITLE_EXCLUSION}/`;


export const GetTitleExclusionProgrammeTitlesAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitleExclusionProgrammeTitles';
  const endpoint = `${apiHost}${apiContext}${api}?page=1&size=10000&titleIds=${queryParams.titleIds}&channelId=${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitleExclusionsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitleExclusions';
  const endpoint = `${apiHost}${apiContext}${api}?channelID=${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitleExclusionTitleDetailsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'getTitleExclusionTitleDetails';
  const endpoint = `${apiHost}${apiContext}${api}?titleId=${queryParams.titleId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};