import axios from 'axios';
import { HttpMethods, APIContexts } from 'enums';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.SEASON}/`;

export const GetSeasonsListAPI = ({
  queryParams,
  requestBody,
  onSuccess,
  ...rest
}) => {
  const api = 'GetSeasonsList';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    payload: requestBody,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetIncludedSeasonsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetIncludedSeasons';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetSeriesAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetSeries';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetSeasonBySeriesAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetSeasonBySeries';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.seriesID}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};
