import { SaveGridStateAPI } from 'api';

export const onGridPreDestroyedEvent = ({ gridName, onGridPreDestroyed, params }) => {

  if (onGridPreDestroyed) onGridPreDestroyed(params);

  if (gridName) {
    const requestBody = {
      grid: gridName,
      state: JSON.stringify(params.state),
    };

    SaveGridStateAPI({ requestBody });
  }
};