import { HttpMethods, APIContexts } from 'enums';
import axios from 'axios';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.INTERSTITIAL}/`;

export const GetInterstitialsAPI = ({
  queryParams,
  requestBody,
  onSuccess,
  ...rest
}) => {
  const api = '';
  const endpoint = `${apiHost}${apiContext}${api}${queryParams.channelId}/interstitialtitleslist`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    payload: requestBody,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetIncludedInterstitialsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = '';
  const endpoint = `${apiHost}${apiContext}${api}${queryParams.channelId}/includedinterstitiallist`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};