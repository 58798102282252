import axios from 'axios';
import { HttpMethods, APIContexts } from 'enums';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.TITLE_GROUP}/`;

export const GetTitlesAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitles';
  const endpoint = `${apiHost}${apiContext}${api}?page=${queryParams.page}&size=${queryParams.size}&titleIds=${queryParams.titleIds}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitleAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitle';
  const endpoint = `${apiHost}${apiContext}${api}/${queryParams.id}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitlesFromGroupAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitlesFromGroup';
  const titleIdsParameter = queryParams.titleIds
    ? `&titleIds=${queryParams.titleIds
        .map((value) => value.titleId)
        .join(',')}`
    : '';
  const endpoint = `${apiHost}${apiContext}${api}?groupId=${queryParams.groupId}${titleIdsParameter}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetTitleGroupsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetTitleGroups';
  const endpoint = `${apiHost}${apiContext}${api}?pageNumber=1&pageSize=10000&prioritisedTitleGroupIds=${queryParams.titleGroupIds}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const GetPrioritisedTitleGroupsAPI = ({
  queryParams,
  onSuccess,
  ...rest
}) => {
  const api = 'GetPrioritisedTitleGroups';
  const endpoint = `${apiHost}${apiContext}${api}?channelId=${queryParams.channelId}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
}

export const GetTitleGroupLookupAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetTitleGroupLookup';
  const endpoint = `${apiHost}${apiContext}${api}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

